import { default as about_45us1QKow8Ex79Meta } from "/vercel/path0/pages/[locale]/about-us.vue?macro=true";
import { default as careers8LVliREKQUMeta } from "/vercel/path0/pages/[locale]/careers.vue?macro=true";
import { default as disclaimerOKm5v2HyQIMeta } from "/vercel/path0/pages/[locale]/disclaimer.vue?macro=true";
import { default as _91fid_93JsG9WhQwQDMeta } from "/vercel/path0/pages/[locale]/events/[fid].vue?macro=true";
import { default as archivewFD3GId9AqMeta } from "/vercel/path0/pages/[locale]/events/archive.vue?macro=true";
import { default as indexy0XS6zLb0pMeta } from "/vercel/path0/pages/[locale]/index.vue?macro=true";
import { default as _91fid_93NPOshHQeiZMeta } from "/vercel/path0/pages/[locale]/lawyers/[fid].vue?macro=true";
import { default as GetLawyerPrintTemplateETpzXGgfqoMeta } from "/vercel/path0/pages/[locale]/lawyers/GetLawyerPrintTemplate.ts?macro=true";
import { default as _91fid_93fWyRhdOpVHMeta } from "/vercel/path0/pages/[locale]/legal-alerts/[fid].vue?macro=true";
import { default as archivejQSShNCtXjMeta } from "/vercel/path0/pages/[locale]/legal-alerts/archive.vue?macro=true";
import { default as indexh1rwFx1JtCMeta } from "/vercel/path0/pages/[locale]/legal-alerts/index.vue?macro=true";
import { default as _91fid_93HFGZMHwgLhMeta } from "/vercel/path0/pages/[locale]/media-articles/[fid].vue?macro=true";
import { default as archivesubPUZy2PgMeta } from "/vercel/path0/pages/[locale]/media-articles/archive.vue?macro=true";
import { default as mediaNcshRVYBaMMeta } from "/vercel/path0/pages/[locale]/media.vue?macro=true";
import { default as peopleGtIxk1hV7pMeta } from "/vercel/path0/pages/[locale]/people.vue?macro=true";
import { default as searchXfiGUTAK40Meta } from "/vercel/path0/pages/[locale]/search.vue?macro=true";
import { default as servicesUIk7Y36FBSMeta } from "/vercel/path0/pages/[locale]/services.vue?macro=true";
import { default as apiControllerjoZl0i1inyMeta } from "/vercel/path0/pages/a/apiController.ts?macro=true";
import { default as apiResponseHandlerOXgfdZgBzWMeta } from "/vercel/path0/pages/a/apiResponseHandler.ts?macro=true";
import { default as bannersvo3nhvYh2DMeta } from "/vercel/path0/pages/a/banners.vue?macro=true";
import { default as discoveryC2tvQVXZ0YMeta } from "/vercel/path0/pages/a/discovery.vue?macro=true";
import { default as edit_45banner3ycTyHgDNRMeta } from "/vercel/path0/pages/a/edit-banner.vue?macro=true";
import { default as edit_45eventnBi4WAen7cMeta } from "/vercel/path0/pages/a/edit-event.vue?macro=true";
import { default as edit_45lawyeranwDI7gkwxMeta } from "/vercel/path0/pages/a/edit-lawyer.vue?macro=true";
import { default as edit_45legal_45alertpsnXVLbOPjMeta } from "/vercel/path0/pages/a/edit-legal-alert.vue?macro=true";
import { default as edit_45media_45articlejU6SXV9ezeMeta } from "/vercel/path0/pages/a/edit-media-article.vue?macro=true";
import { default as edit_45section_45headerb10PCaM4AAMeta } from "/vercel/path0/pages/a/edit-section-header.vue?macro=true";
import { default as eventszTn2HDY0wnMeta } from "/vercel/path0/pages/a/events.vue?macro=true";
import { default as forcedReloaderGKTQNwp5PWMeta } from "/vercel/path0/pages/a/forcedReloader.ts?macro=true";
import { default as indexAGnRyGPtHyMeta } from "/vercel/path0/pages/a/index.vue?macro=true";
import { default as lawyers25BNe1HBc8Meta } from "/vercel/path0/pages/a/lawyers.vue?macro=true";
import { default as legal_45alertsbnrAnx3MVCMeta } from "/vercel/path0/pages/a/legal-alerts.vue?macro=true";
import { default as media_45articleshn6LAC88b2Meta } from "/vercel/path0/pages/a/media-articles.vue?macro=true";
import { default as modelReloaderNaQTupUAcYMeta } from "/vercel/path0/pages/a/modelReloader.ts?macro=true";
import { default as section_45headerszhnuFAmgAOMeta } from "/vercel/path0/pages/a/section-headers.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
export default [
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/vercel/path0/pages/[locale]/about-us.vue").then(m => m.default || m)
  },
  {
    name: "locale-careers",
    path: "/:locale()/careers",
    component: () => import("/vercel/path0/pages/[locale]/careers.vue").then(m => m.default || m)
  },
  {
    name: "locale-disclaimer",
    path: "/:locale()/disclaimer",
    component: () => import("/vercel/path0/pages/[locale]/disclaimer.vue").then(m => m.default || m)
  },
  {
    name: "locale-events-fid",
    path: "/:locale()/events/:fid()",
    component: () => import("/vercel/path0/pages/[locale]/events/[fid].vue").then(m => m.default || m)
  },
  {
    name: "locale-events-archive",
    path: "/:locale()/events/archive",
    component: () => import("/vercel/path0/pages/[locale]/events/archive.vue").then(m => m.default || m)
  },
  {
    name: "locale",
    path: "/:locale()",
    component: () => import("/vercel/path0/pages/[locale]/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-lawyers-fid",
    path: "/:locale()/lawyers/:fid()",
    component: () => import("/vercel/path0/pages/[locale]/lawyers/[fid].vue").then(m => m.default || m)
  },
  {
    name: "locale-lawyers-GetLawyerPrintTemplate",
    path: "/:locale()/lawyers/GetLawyerPrintTemplate",
    component: () => import("/vercel/path0/pages/[locale]/lawyers/GetLawyerPrintTemplate.ts").then(m => m.default || m)
  },
  {
    name: "locale-legal-alerts-fid",
    path: "/:locale()/legal-alerts/:fid()",
    component: () => import("/vercel/path0/pages/[locale]/legal-alerts/[fid].vue").then(m => m.default || m)
  },
  {
    name: "locale-legal-alerts-archive",
    path: "/:locale()/legal-alerts/archive",
    component: () => import("/vercel/path0/pages/[locale]/legal-alerts/archive.vue").then(m => m.default || m)
  },
  {
    name: "locale-legal-alerts",
    path: "/:locale()/legal-alerts",
    component: () => import("/vercel/path0/pages/[locale]/legal-alerts/index.vue").then(m => m.default || m)
  },
  {
    name: "locale-media-articles-fid",
    path: "/:locale()/media-articles/:fid()",
    component: () => import("/vercel/path0/pages/[locale]/media-articles/[fid].vue").then(m => m.default || m)
  },
  {
    name: "locale-media-articles-archive",
    path: "/:locale()/media-articles/archive",
    component: () => import("/vercel/path0/pages/[locale]/media-articles/archive.vue").then(m => m.default || m)
  },
  {
    name: "locale-media",
    path: "/:locale()/media",
    component: () => import("/vercel/path0/pages/[locale]/media.vue").then(m => m.default || m)
  },
  {
    name: "locale-people",
    path: "/:locale()/people",
    component: () => import("/vercel/path0/pages/[locale]/people.vue").then(m => m.default || m)
  },
  {
    name: "locale-search",
    path: "/:locale()/search",
    component: () => import("/vercel/path0/pages/[locale]/search.vue").then(m => m.default || m)
  },
  {
    name: "locale-services",
    path: "/:locale()/services",
    component: () => import("/vercel/path0/pages/[locale]/services.vue").then(m => m.default || m)
  },
  {
    name: "a-apiController",
    path: "/a/apiController",
    component: () => import("/vercel/path0/pages/a/apiController.ts").then(m => m.default || m)
  },
  {
    name: "a-apiResponseHandler",
    path: "/a/apiResponseHandler",
    component: () => import("/vercel/path0/pages/a/apiResponseHandler.ts").then(m => m.default || m)
  },
  {
    name: "a-banners",
    path: "/a/banners",
    meta: bannersvo3nhvYh2DMeta || {},
    component: () => import("/vercel/path0/pages/a/banners.vue").then(m => m.default || m)
  },
  {
    name: "a-discovery",
    path: "/a/discovery",
    meta: discoveryC2tvQVXZ0YMeta || {},
    component: () => import("/vercel/path0/pages/a/discovery.vue").then(m => m.default || m)
  },
  {
    name: "a-edit-banner",
    path: "/a/edit-banner",
    component: () => import("/vercel/path0/pages/a/edit-banner.vue").then(m => m.default || m)
  },
  {
    name: "a-edit-event",
    path: "/a/edit-event",
    component: () => import("/vercel/path0/pages/a/edit-event.vue").then(m => m.default || m)
  },
  {
    name: "a-edit-lawyer",
    path: "/a/edit-lawyer",
    component: () => import("/vercel/path0/pages/a/edit-lawyer.vue").then(m => m.default || m)
  },
  {
    name: "a-edit-legal-alert",
    path: "/a/edit-legal-alert",
    component: () => import("/vercel/path0/pages/a/edit-legal-alert.vue").then(m => m.default || m)
  },
  {
    name: "a-edit-media-article",
    path: "/a/edit-media-article",
    component: () => import("/vercel/path0/pages/a/edit-media-article.vue").then(m => m.default || m)
  },
  {
    name: "a-edit-section-header",
    path: "/a/edit-section-header",
    component: () => import("/vercel/path0/pages/a/edit-section-header.vue").then(m => m.default || m)
  },
  {
    name: "a-events",
    path: "/a/events",
    meta: eventszTn2HDY0wnMeta || {},
    component: () => import("/vercel/path0/pages/a/events.vue").then(m => m.default || m)
  },
  {
    name: "a-forcedReloader",
    path: "/a/forcedReloader",
    component: () => import("/vercel/path0/pages/a/forcedReloader.ts").then(m => m.default || m)
  },
  {
    name: "a",
    path: "/a",
    meta: indexAGnRyGPtHyMeta || {},
    component: () => import("/vercel/path0/pages/a/index.vue").then(m => m.default || m)
  },
  {
    name: "a-lawyers",
    path: "/a/lawyers",
    meta: lawyers25BNe1HBc8Meta || {},
    component: () => import("/vercel/path0/pages/a/lawyers.vue").then(m => m.default || m)
  },
  {
    name: "a-legal-alerts",
    path: "/a/legal-alerts",
    meta: legal_45alertsbnrAnx3MVCMeta || {},
    component: () => import("/vercel/path0/pages/a/legal-alerts.vue").then(m => m.default || m)
  },
  {
    name: "a-media-articles",
    path: "/a/media-articles",
    meta: media_45articleshn6LAC88b2Meta || {},
    component: () => import("/vercel/path0/pages/a/media-articles.vue").then(m => m.default || m)
  },
  {
    name: "a-modelReloader",
    path: "/a/modelReloader",
    component: () => import("/vercel/path0/pages/a/modelReloader.ts").then(m => m.default || m)
  },
  {
    name: "a-section-headers",
    path: "/a/section-headers",
    meta: section_45headerszhnuFAmgAOMeta || {},
    component: () => import("/vercel/path0/pages/a/section-headers.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  }
]